<template>
    <div>
        <b-container class="pt-5 pb-5">
            <div class="text-center">
                <img alt="Tixgo" src="../assets/logo.svg" class="mb-4" width="120" />
            </div>
            <b-card no-body class="small-card">
                <b-card-body>
                    <div class="text-center">
                        <font-awesome-icon class="text-primary mb-3" icon="fa-solid fa-envelope" size="5x" />
                        <template v-if="has_success">
                            <h1 class="fs-3 fs-light mb-3">Verificatie geslaagd</h1>
                            <div class="m-auto text-muted" style="max-width:300px;">
                                <p>Uw account is zojuist geactiveerd. Klik op de onderstaande knop om in te loggen.</p>
                                <b-button to="/account/login" variant="primary">Ga verder naar inloggen</b-button>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="has_error">
                                <h1 class="fs-3 fs-light mb-3">Geactiveerd of niet gevonden</h1>
                                <p>Uw account is reeds geactiveerd of kan niet worden gevonden.</p>
                                <b-row>
                                    <b-col cols="12" lg="6" class="mb-3">
                                        <b-button to="/account/login" variant="primary" block>Inloggen</b-button>
                                    </b-col>
                                    <b-col cols="12" lg="6" class="mb-3">
                                        <b-button to="/account/register" variant="primary" block>Registeren</b-button>
                                    </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                                <h1 class="fs-3 fs-light mb-3">Wachten op e-mail verificatie</h1>
                                <div class="m-auto text-muted" style="max-width:300px;">
                                    <p>U ontvangt per e-mail een link om uw e-mailadres te verifiëren.</p>
                                    <p>Ga naar uw e-mailinbox, verifieer uw e-mailadres en volg de instructies.</p>
                                </div>
                            </template>
                        </template>
                    </div>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    //import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            //ValidationObserver,
            //ValidationProvider,
        },
        metaInfo: {
            title: 'E-mail verificatie',
        },
        data() {
            return {
                has_success: false,
                has_error: false,
                form: {
                    token: '',
                    username: '',
                }
            }
        },
        methods: {
            verifyAccount: function() {
                this.has_success = false;
                this.has_error = false;
                
                this.$axios.post("https://api.tixgo.nl/accounts/verify", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            this.has_success = true;
                        } else {
                            this.has_error = true;
                        }
                    }
                );
            }
        },
        created() {
            if (this.$route.query.token && this.$route.query.email) {

                this.form.token = this.$route.query.token;
                this.form.username = this.$route.query.email;
                
                this.verifyAccount();
            }
        }
    });
</script>

<style scoped>
    .small-card{
        max-width:90%;
        margin:auto;
    }

    @media screen and (min-width:600px) {
        .small-card{
            max-width:500px;
        }
    }
</style>